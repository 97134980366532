import "./index.scss";
import { useContext } from "react";
import Context from "../../Context";
import { Link } from "react-router-dom";
import Logo from "../../Assets/images/logo.svg";
import MenuIcon from "../../Assets/images/icons/menu.svg";
import MenuClose from "../../Assets/images/icons/close.svg";

const Header = () => {
    const { menuOpen, setMenuOpen, resumo } = useContext(Context);
    return (
        <div id="header">
            <div className="container">
                <Link to="/"><img src={Logo} alt="Bento's" /></Link>
                <img onClick={() => setMenuOpen(!menuOpen)} src={menuOpen ? MenuClose : MenuIcon} alt="Menu" />
                <div className={`menu ${menuOpen ? "open" : ""}`}>
                    {resumo && resumo.menus && resumo.menus.principal && resumo.menus.principal.map(item => <Link key={`menu-item-header-${item.nome}`} to={item.link}>{item.nome}</Link>)}
                </div>
            </div>
        </div>
    )
};

export default Header;



