import "./index.scss";
import Logo from "../../Assets/images/logo-f.svg";
import { useContext } from "react";
import Context from "../../Context";
import { Link } from "react-router-dom";
import Facebook from "../../Assets/images/icons/facebook.svg";
import Instagram from "../../Assets/images/icons/instagram.svg";
import WhatsApp from "../../Assets/images/icons/whatsapp.svg";
import { numbersOnly } from "../../Utils";

const Footer = () => {
    const { resumo } = useContext(Context);
    return (
        <div id="footer">
            <div className="container">
                <div className="logo">
                    <img src={Logo} alt="Bento's" />
                </div>
                <div className="conteudo">
                    <div className="menu">
                        {resumo && resumo.menus && resumo.menus.rodape && resumo.menus.rodape.map(item => <Link key={`menu-item-footer-${item.nome}`} to={item.link}>{item.nome}</Link>)}
                        <div className="socials">
                            {resumo.configuracoes.instagram.length > 5 && <a href={resumo.configuracoes.instagram} target="_blank" title="Instagram" rel="noopener noreferrer"><img src={Instagram} alt="Instagram" /></a>}
                            {resumo.configuracoes.facebook.length > 5 && <a href={resumo.configuracoes.facebook} target="_blank" title="Facebook" rel="noopener noreferrer"><img src={Facebook} alt="Facebook" /></a>}
                            {resumo.configuracoes.whatsapp.length > 5 && <a href={`https://wa.me/55${numbersOnly(resumo.configuracoes.whatsapp)}`} target="_blank" title="WhatsApp" rel="noopener noreferrer"><img src={WhatsApp} alt="WhatsApp" /></a>}
                        </div>
                        <Link className="contato" to="/contato">Fale conosco</Link>
                    </div>
                    <div className="bottom">
                        {resumo.configuracoes && <p>{resumo.configuracoes.endereco} - {resumo.configuracoes.bairro}, {resumo.configuracoes.cidade} - {resumo.configuracoes.estado}</p>}
                        <p className="creditos">Desenvolvido por <a href="https://fw2propaganda.com.br/" target={"_blank"} rel="noopener noreferrer" title="Gustavo Barbosa">FW2 Propaganda ❤</a></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;