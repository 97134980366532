import './App.css';
import { Suspense, lazy, useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import Context from './Context';
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import ScrollToTop from "./Components/ScrollToTop";
import axios from 'axios';
import Cookies from './Components/Cookies';
import Metas from './Components/Metas';
import TagManager from 'react-gtm-module';
import { numbersOnly } from './Utils';

import Whatsapp from "./Assets/images/icons/whatsapp.svg";

const Home = lazy(() => import('./Pages/Home'));
const Sobre = lazy(() => import('./Pages/Sobre'));
const Obrigado = lazy(() => import('./Pages/Obrigado'));
const Ofertas = lazy(() => import('./Pages/Ofertas'));
const Oferta = lazy(() => import('./Pages/Oferta'));
const Contato = lazy(() => import('./Pages/Contato'));
const Texto = lazy(() => import('./Pages/Texto'));

function App() {
  localStorage.setItem("apiUrl", "/sistema/api/");
  localStorage.setItem("apiUrl", "https://bentosmateriais.com.br/sistema/api/");
  const [resumo, setResumo] = useState({});
  const [modal, setModal] = useState(false);
  const [activePage, setActivePage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [cart, setCart] = useState([]);
  const [metas, setMetas] = useState({
    titulo: "",
    descricao: "",
    imagem: "",
    keywords: "",
    href: "",
    type: "website",
    card: "summary"
  });
  const value = { resumo, setResumo, modal, setModal, activePage, setActivePage, menuOpen, setMenuOpen, cart, setCart, metas, setMetas };

  useEffect(() => {
    axios.get(localStorage.getItem("apiUrl") + "configuracoes").then(response => {
      setResumo(response.data.success);
      if (response.data.success.configuracoes.tag_manager) {
        if (response.data.success.configuracoes.tag_manager.length > 5) {
          const tagManagerArgs = {
            gtmId: response.data.success.configuracoes.tag_manager
          }
          let checkCookies = setInterval(() => {
            let accept = localStorage.getItem("fw2AcceptCookiesDesnecessarios");
            if (accept) {
              TagManager.initialize(tagManagerArgs)
              clearInterval(checkCookies);
            }
          }, 3000);
        }
      }
    });

    let carrinho = JSON.parse(localStorage.getItem("carrinho"));
    if (!carrinho) {
      carrinho = []
    }
    setCart(carrinho);

  }, []);

  return (
    <div className="App">
      <HelmetProvider>
        <Context.Provider value={value}>
          <div id="modal" className={modal ? "active" : ""}>
            {modal && <div className="content">
              <h3>{modal.titulo}</h3>
              <p>{modal.texto}</p>
              <button type="button" onClick={() => { if (modal.onExit) { modal.onExit() } setModal(false) }}>{modal.botao}</button>
            </div>}
          </div>
          <Metas {...metas} />
          {resumo.textos &&
            <Router>
              <Header />
              <ScrollToTop setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
              <Suspense fallback={<div className="suspense"></div>}>
                <Switch>
                  <Route exact path="/">
                    <Home />
                  </Route>
                  <Route exact path="/sobre">
                    <Sobre />
                  </Route>
                  <Route exact path="/ofertas">
                    <Ofertas />
                  </Route>
                  <Route exact path="/oferta/:slug">
                    <Oferta />
                  </Route>
                  <Route exact path="/contato">
                    <Contato />
                  </Route>
                  <Route exact path="/obrigado/:slug">
                    <Obrigado />
                  </Route>
                  {resumo.textos &&
                    <Route exact path="/termos-de-uso">
                      <Texto titulo={"Termos de uso"} texto={resumo.textos.termos_de_uso} />
                    </Route>
                  }
                  {resumo.textos &&
                    <Route exact path="/politica-de-privacidade">
                      <Texto titulo={"Política de privacidade"} texto={resumo.textos.politica_de_privacidade} />
                    </Route>
                  }
                </Switch>
              </Suspense>
              <Cookies />
              <Footer />
            </Router>
          }
        </Context.Provider>
      </HelmetProvider>
      {resumo.configuracoes && resumo.configuracoes.whatsapp.length > 8 &&
        <a href={`https://wa.me/55${numbersOnly(resumo.configuracoes.whatsapp)}`} target="_blank" rel="noreferrer noopener" className="floating-whatsapp" title="Fale conosco">
          <img src={Whatsapp} width="48px" height="48px" alt="whatsapp" />
        </a>}
    </div>
  );
}

export default App;
