import React from 'react';

const Context = React.createContext({
    resumo: {},
    setResumo: () => { },
    modal: false,
    setModal: () => { },
    activePage: false,
    setActivePage: () => { },
    menuOpen: false,
    setMenuOpen: () => { },
    cart: [],
    setCart: () => { },
    metas: {
        titulo: "",
        descricao: "",
        imagem: "",
        keywords: "",
        href: "",
        type: "website",
        card: "summary"
    },
    setMetas: () => { }
});

export default Context;